import React from 'react';
import OpeningAnimation from './components/openingAnimation';
import bgImg from '../../assets/images/home/bg_layer.png';
import './index.scss';

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.qrRef = React.createRef();
    this.homeRef = React.createRef();
    this.layerRef = React.createRef();
    this.state = {
      videoPlayed: false,
      activeTab: "sgx"
    }
  }
  componentWillMount() {
  }
  componentDidMount() {
    // const flag = window.sessionStorage.getItem('videoEnded');
    // if (flag) {
    //   this.setState({videoPlayed: true})
    //   this.homeRef.current.classList.add('landing');
    // } else {
    //   this.homeRef.current.classList.add('playing');
    // }
    this.homeRef.current.classList.add('playing');
    const layerDOM = this.layerRef.current;
    // 动画效果
    let num = -10;
    // setInterval(() => {
    //   if (num <= -60) {
    //     layerDOM.style.transform = 'rotateZ(-20deg) translate(0, -10%)';
    //     num = -10;
    //   }
    //   num = num - 0.004;
    //   layerDOM.style.transform = 'rotateZ(-20deg) translate(0, ' + num + '%)';
    // }, 10);
    requestAnimationFrame(function fn() {
      if (num <= -60) {
        layerDOM.style.transform = 'rotateZ(-20deg) translate(0, -10%)';
        num = -10;
      }
      num = num - 0.005;
      layerDOM.style.transform = 'rotateZ(-20deg) translate(0, ' + num + '%)';
      requestAnimationFrame(fn);
    });
  }
  componentWillUnmount() {
    this.homeRef.current.classList.remove('landing');

    // 底部备案字体颜色修改
    document.querySelector('.footer').classList.remove('home');
    document.querySelector('.footer').classList.add('other');
  }
  // 显示隐藏二维码
  toggleQR = () => {
    this.qrRef.current.classList.toggle('active');
  }
  videoEndHandler = () => {
    window.sessionStorage.setItem('videoEnded', true);
    this.setState({ videoPlayed: true });
    this.homeRef.current.classList.add('landing');
    this.homeRef.current.classList.remove('playing');

    // 底部备案字体颜色修改
    document.querySelector('.footer').classList.remove('other');
    document.querySelector('.footer').classList.add('home');
  }

  // 点击Tab
  onHandleTab = (activeTab) => {
    this.setState({ activeTab })
  }
  //请求获取下载链接并跳转
  toDownloadApp(event, val) {
    event.preventDefault();
    let params = {
      key: val,
    }
    fetch('https://collect.weilaizhushou.com/base/callback/v1/getUrl', {
      body: JSON.stringify(params),
      headers: {
        'content-type': 'application/json;charset=UTF-8'
      },
      method: 'POST'
    })
      .then(res => res.json())
      .then(res => {
        if (res.code === 200) {
          window.location.href = res.data.url;
        }
      })
      .catch(error => console.error('Error:', error));

  }

  render() {
    const { activeTab } = this.state;
    return (
      <div className="home-container" ref={this.homeRef}>
        {/* 动图背景层 */}
        <div className="background-layer">
          <div className="layer-container" ref={this.layerRef}>
            {/* <div className="upper-layer"></div>
            <div className="bottom-layer"></div> */}
            {/* 额外的图片防止页面宽高过小时图片滚动到边缘 */}
            <img src={bgImg} alt="background" className="additional-layer additional-layer-before" />
            <img src={bgImg} alt="background" className="upper-layer" />
            <img src={bgImg} alt="background" className="bottom-layer" />
            <img src={bgImg} alt="background" className="additional-layer additional-layer-after" />
          </div>
        </div>
        {/* 矩形装饰层 */}
        <div className="left-rect"></div>
        <div className="right-rect"></div>
        {/* 动画播放器 */}
        <OpeningAnimation hidden={this.state.videoPlayed} videoEndHandler={this.videoEndHandler} />
        {/* 主要内容 */}
        <div className="content-layer">
          {/* 时光序 */}
          <div className={`content ${activeTab === 'sgx' ? 'active' : ''}`}>
            <div className="tab-content">
              <p className="slogon">时间管理就用时光序</p>
              <a className="web-btn" href="https://web.shiguangxu.com" target="_blank" rel="noopener noreferrer">
                Web版登录
              </a>
              <section className="downloadButton">
                <section className="buttonType">
                  <a className="apple" href="https://apps.apple.com/cn/app/%E6%97%B6%E5%85%89%E5%BA%8F-%E6%97%A5%E7%A8%8B%E6%B8%85%E5%8D%95%E8%AE%A1%E5%88%92%E6%8F%90%E9%86%92%E4%BA%8B%E9%A1%B9/id1343731648#?platform=iphone" target="_blank" rel="noopener noreferrer">
                    <i></i>
                  </a>
                  <p>iOS</p>
                  <section className='qrCode macQRCode'></section>
                </section>

                <section className="buttonType">
                  <a className="android" href="https://android.myapp.com/myapp/detail.htm?apkName=com.duorong.smarttool&ADTAG=mobile" target="_blank" rel="noopener noreferrer">
                    <i></i>
                  </a>
                  <p>android</p>
                  <section className='qrCode'></section>
                </section>

                <section className="buttonType">
                  <a onClick={(e) => this.toDownloadApp(e, 'pc-sgx/latest/shiguangxu.dmg')} className="macOS" download rel="noopener noreferrer">
                    <i></i>
                  </a>
                  <p>macOS</p>
                </section>

                <section className="buttonType">
                  <a onClick={(e) => this.toDownloadApp(e, 'pc-sgx/latest/shiguangxu.exe')} className="windows" download rel="noopener noreferrer">
                    <i></i>
                  </a>
                  <p>Windows</p>
                </section>
              </section>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
