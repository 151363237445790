import React from "react";
import util from "../../utils";

import "./index.scss";

export default class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
    };
  }
  componentDidMount() {}
  // 滚动事件处理器
  scrollHandler = (e) => {
    const direction = e.deltaY < 0 ? "up" : "down";
    // 向上
    let currentIndex = 0;
    if (direction === "up") {
      if (this.state.currentIndex === 0) {
        currentIndex = 0;
      } else {
        currentIndex = this.state.currentIndex - 1;
      }
    } else {
      // 向下
      if (this.state.currentIndex === 2) {
        currentIndex = 2;
      } else {
        currentIndex = this.state.currentIndex + 1;
      }
    }
    this.setState({ currentIndex });
  };
  // 节流滚动事件处理器
  throttleScroll = util.throttle(this.scrollHandler, 1500);
  backToTop = () => {
    this.setState({ currentIndex: 0 });
  };
  // app应用列表渲染
  liElement = () => {
    const appList = [
      {
        id: "sgx",
        appName: "时光序",
        desc: "是一款轻便的个人生活管理应用，你可以用它管理日程，设定纪念日和关心家人提醒，随时捕捉生活中的灵感、总结自己。是你工作和生活，学习的智能助手。",
        cell: [
          "智能语音：只需一个口令，便能知你所想懂你所需;",
          "时间管理：每日每周每月，多种视图展示，帮你规划每一天;",
          "桌面组件：不用特意点进去APP，在桌面就可轻松管理，超方便;",
          "定制界面：自由定制界面选项卡、打造个人专属应用。",
        ],
        downLink:
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.duorong.smarttool",
      },
      {
        id: "jizhang",
        appName: "时光记账",
        desc: "这是一款轻量级记账软件，帮助用户快速、实时的记录每日收入支出。",
        cell: [
          "记账方式：手动记账，周期记账，数据导入。",
          "多账本：可创建多个账本，按不同场景管理；",
          "钱包管理：强大的资产管理功能，可创建信用卡、借记卡、充值卡等不同的资产账户；",
          "预算：支持设置总预算和分类预算，支持设置月度、季度、年度预算；",
          "统计：折线图、饼图，按天、周、月、年自由切换、周期对比掌握收支变化。",
        ],
        downLink:
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.duorong.smarttool.bill",
      },
      {
        id: "mubiao",
        appName: "时光flag",
        desc: "一个帮助用户成长的效率类软件，设定目标，按照时间自动拆解步骤，按计划去执行，最终变成更好的自己。",
        cell: [
          "多类型目标：可设置无数量限制的目标和按指定量级完成目标；",
          "周期目标：支持按周、月、季、年设定目标周期；",
          "目标拆解：支持对总目标增加子目标，精细化管理目标；",
          "图表分析：根据目标完成时间、完成量，生成时间轴记录；日、周、月、年进度。",
        ],
        downLink:
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.duorong.smarttool.target",
      },
      {
        id: "daka",
        appName: "时光打卡",
        desc: "这是一个帮助用户成长的效率类软件，设定需要培养习惯后，按照时间，每天坚持打卡，成为更好的自己。",
        cell: [
          "习惯库：丰富的习惯库和场景图标;",
          "目标量：可设置永久的习惯和指定量级的习惯;",
          "感想留言：每日打卡后，可填写当日感想，进行回顾;",
          "桌面组件：桌面添加习惯，快速打卡。",
        ],
        downLink:"https://a.app.qq.com/o/simple.jsp?pkgname=com.duorong.smarttool.habit",
      },
      {
        id: "daoshu",
        appName: "时光倒数",
        desc: "时光倒数是一个帮助用户记录生活中重要日子的工具，方便提前准备。",
        cell: [
          "倒数日：重要日子看着一步步来临，充满期待;",
          "纪念日：一生中有很多值得纪念的日子，不再错过;",
          "生日：记录亲密人的生日，给Ta一个惊喜;",
          "节日：传统、国际、各类节日随意添加查看。",
        ],
        downLink:"https://a.app.qq.com/o/simple.jsp?pkgname=com.duorong.smarttool.anniversary",
      },
      {
        id: "shixiang",
        appName: "时光待办",
        desc: "时光待办是一个帮助用户记录类软，设置日程后，准时或提前提醒用户，以免错过重要事情。",
        cell: [
          "日程：添加未来的事情，记录和提醒;",
          "重复：周期性要做的事情，每天、每周、每月、自定义各种范围;",
          "备忘：富文本图文混排，丰富的自定义样式;",
          "专注：专心做一件事情时，设置时间，添加感想，便于回顾;",
          "视图：日、周、月视图，不同时间维度查看所有事情。",
        ],
        downLink:"https://a.app.qq.com/o/simple.jsp?pkgname=com.duorong.smarttool.schedule",
      },
    ];

    // 遍历渲染
    return appList.map((item) => {
      return (
        <li>
          <div class="app_list_l">
            <img
              src={require("../../assets/images/about/logo_" +
                item.id +
                ".png")}
              alt=""
            />
            <div>
              <span>{item.appName}</span>
            </div>
            {item.downLink ? (
              <a href={item.downLink} target="_blank" rel="noopener noreferrer">
                点击下载
              </a>
            ) : (
              "即将上线"
            )}
          </div>
          <div class="app_list_r">
            <h2>{item.appName}</h2>
            <p>{item.desc}</p>
            <p>特点：</p>
            {item.cell.map((cell) => {
              return <p>{cell}</p>;
            })}
          </div>
        </li>
      );
    });
  };
  render() {
    return (
      <div className={"about-us step" + this.state.currentIndex}>
        <div className="left-container">
          <div className="left-rect rect"></div>
        </div>
        <div className="right-rect"></div>
        <div className="first-page page">
          <h2 class="title">花生未来（广州）科技有限公司</h2>
          <p class="destiny">
            致力于研发生活服务类APP。帮助用户完成事项提醒、目标、记账、备忘、饮食、生理期、穿衣搭配等诸多功能，多款应用配合使用，满足您日常工作、生活各种场景化需求。超个性化功能定制，实现了页面的简约而不简单，功能丰富而不赘余，真正做到私人管家。
          </p>
          <p>
            <div class="app_list_head">【旗下APP】</div>
            <ul class="app_list">
              {this.liElement()}
            </ul>
          </p>
          <div class="destiny">
            <p>【应用场景】</p>
            <p>
              清晨，你在舒缓轻柔的音乐闹钟中缓缓醒过来，饮入小序贴心提醒的第一杯水，唤醒身体活力机能。三餐时间，都有小序为你精心定制的营养餐单，全方位记录健康情况。最新时事新闻、历史今日情况、是否有重要纪念日子……小序帮你实时播报每日小概况。
            </p>
            <p>
              不知道今天要做什么？问问小序，她会为你规划日程。今日天气如何？适宜的穿搭？小序智能根据天气场合，为你贴心准备外出穿搭，今天运势如何、宜做什么忌什么，万能小序她都知道。你要去哪里，告诉小序，她会直接领着你走。在路上想消费，小序还会为你精打细算记好账。什么时候生理期？什么时候还款？到点自动提醒，一件事都不会漏掉。
            </p>
            <p>
              如果你是一名上班族/学生党，她会帮你牢牢记得上班表/课程表，什么时候上班或上课，她会贴心提醒你。小序还会为你记录代办事项，创造最佳专注环境，让你开启高效的一天，工作学习时忘了外文，记得让小序帮你翻译哦。定目标、戒坏习惯、追梦路上小序会一直相伴左右，如果坚持过程感到疲累，小序还会给你讲笑话，放松一下。
            </p>
            <p>
              夜晚，小序会陪你一起，回顾记录这一天发生的点滴。不开心时可以在树洞敞开心扉诉说心事，这里还汇集各类不同的圈子，你总可以找到懂你的人。你酣然入梦，小序也毫不松懈，为你记录睡眠数据、检测睡眠质量。
            </p>
            <p>
              你的一天24小时，小序全天候在线，贴心管理你生活方方面面，只为你一个人而服务。
            </p>
          </div>
          <div class="company-info">
            <h3>联系地址</h3>
            <p class="company-name">花生未来（广州）科技有限公司</p>
            <p>地址：广州市天河区花城大道667号美林基业1203</p>
          </div>
          <div class="bottom"></div>
        </div>
      </div>
    );
  }
}
